import React from 'react'
import Helmet from 'react-helmet'
import { Pane, UnorderedList, ListItem } from '@muhsis/ui'
import { Row, Col } from 'react-styled-flexboxgrid'

import {
  Layout,
  SEO,
  BackgroundImageHome,
  ImageFluid,
  withSplashScreen,
} from '../components'
import {
  Header,
  Spotlight,
  SpotlightDark,
  SpotlightHeading,
  FeatureMain,
  Support,
  Footer,
} from '../blocks'
import {
  imgMuhsisWeb,
  imgFeatureDocuments,
  imgFeaturePayments,
  imgFeatureReceiptDesigner,
} from '../assets/images'

const IndexPage = () => (
  <Layout>
    <SEO
      title=""
      keywords={[
        `web tabanlı ön muhasebe`,
        `muhasebe programı`,
        `web muhasebe programı`,
        `online muhasebe programı`,
      ]}
    />
    <Helmet
      title="Muhsis, Web Tabanlı Ön Muhasebe Programı"
      titleTemplate="%s"
    />
    <BackgroundImageHome>
      <Header />
      <Spotlight />
    </BackgroundImageHome>

    <Pane marginTop="-2rem" marginBottom="5rem" textAlign="center">
      <ImageFluid
        src={imgMuhsisWeb}
        alt="Muhsis, Web Tabanlı Ön Muhasebe Programı"
        style={{ zIndex: '1' }}
      />
    </Pane>

    <Pane background="#F5FAFE" paddingY="5rem">
      <SpotlightHeading
        icon="comparison"
        title="İhtiyacınız olan ne varsa, Muhsis'te var.
"
        description="Ön muhasebe ve finans süreçlerinizi Muhsis ile yönetin, zaman kazanın."
      />

      <FeatureMain
        orientation="left"
        image={imgFeatureDocuments}
        suptitle="BELGE YÖNETİMİ"
        title="Fatura ve İrsaliyelerinizi Yönetin"
        description="Fatura, irsaliye, sipariş, yurt dışı fatura, proforma fatura,iade fatura ve stok fişi gibi tüm alış ve satış belgelerini yönetebilirsiniz. Gelişmiş filtreleme sistemi ile aradığınız belgeye rahatlıkla ulaşırsınız. Belgeler arası dönüştürme yapmak çok kolaydır."
      >
        <Row>
          <Col sm={6}>
            <UnorderedList size={400} icon="tick">
              <ListItem> Tevkifat uygulama</ListItem>
              <ListItem>Gelişmiş filtreleme</ListItem>
              <ListItem>Fatura paylaşımı</ListItem>
            </UnorderedList>
          </Col>

          <Col sm={6}>
            <UnorderedList size={400} icon="tick">
              <ListItem>Yazdırılabilir şablonlar</ListItem>
              <ListItem>Belge dönüşümleri</ListItem>
              <ListItem>İndirim yönetimi</ListItem>
            </UnorderedList>
          </Col>
        </Row>
      </FeatureMain>

      <FeatureMain
        orientation="right"
        image={imgFeaturePayments}
        suptitle="ÖDEME VE TAHSİLAT YÖNETİMİ"
        title="Şirketinizin Para Akışını Yönetin"
        description="Müşterilerinizden alacağınız ve tedarikçilerinize yapacağınız tüm ödemelerinizi Muhsis ile yönetebilir, gelişmiş filtreleme ve bildirim sistemi sayesinde, tarihi yaklaşan veya geçen ödemelerinizi asla gözden kaçırmazsınız."
      >
        <Row>
          <Col sm={6}>
            <UnorderedList size={400} icon="tick">
              <ListItem>Nakit ödemeler</ListItem>
              <ListItem>Gelişmiş filtreleme</ListItem>
              <ListItem>Döviz kurları</ListItem>
            </UnorderedList>
          </Col>

          <Col sm={6}>
            <UnorderedList size={400} icon="tick">
              <ListItem>Kredi kartı yönetimi</ListItem>
              <ListItem>Havale yönetimi</ListItem>
              <ListItem>Çek yönetimi</ListItem>
            </UnorderedList>
          </Col>
        </Row>
      </FeatureMain>

      <FeatureMain
        orientation="left"
        image={imgFeatureDocuments}
        suptitle="KASA VE BANKA HESAPLARINIZ"
        title="Tüm Hesaplarınızı Yönetin"
        description="Kasa hesaplarınızı, çalıştığınız tüm bankalardaki vadeli/vadesiz, yurtiçi/yurtdışı hesaplarınızı, ayrıca kredi hesaplarınızı, pos cihazlarınızı ve kredi kartlarınızı Muhsis ile yönetebilir, ödeme ve tahsilatlarınızda kullanabilirsiniz."
      >
        <Row>
          <Col sm={6}>
            <UnorderedList size={400} icon="tick">
              <ListItem>Kasa hesapları</ListItem>
              <ListItem>Kredi kartları</ListItem>
              <ListItem>Döviz hesaplar</ListItem>
            </UnorderedList>
          </Col>

          <Col sm={6}>
            <UnorderedList size={400} icon="tick">
              <ListItem>Banka hesapları</ListItem>
              <ListItem>POS cihazları</ListItem>
              <ListItem>Raporlama</ListItem>
            </UnorderedList>
          </Col>
        </Row>
      </FeatureMain>
    </Pane>

    <Pane paddingY="5rem">
      <FeatureMain
        orientation="right"
        image={imgFeatureReceiptDesigner}
        suptitle="FATURA TASARIM ARACI"
        title="Tüm Belge Türlerinizi Yazdırın"
        description="Fatura, irsaliye ve diğer tüm belgeleriniz için, belge tasarım aracımız ile fatura tasarımınıza uygun yazdırma şablonları hazırlayabilir ve belgelerinizi yazdırırken kullanabilirsiniz."
      >
        <Row>
          <Col sm={6}>
            <UnorderedList size={400} icon="tick">
              <ListItem>Sınırsız şablon</ListItem>
              <ListItem>Belge yazdırma</ListItem>
            </UnorderedList>
          </Col>

          <Col sm={6}>
            <UnorderedList size={400} icon="tick">
              <ListItem>Tasarım aracı</ListItem>
              <ListItem>Kolay kullanım</ListItem>
            </UnorderedList>
          </Col>
        </Row>
      </FeatureMain>
    </Pane>

    <SpotlightDark />
    <Support />

    <Footer />
  </Layout>
)

export default withSplashScreen(IndexPage)
